// store.js
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./index"; // Create this file

const store = configureStore({
  reducer: rootReducer,
  // You can also configure other options here
});

export default store;
