import React from "react";

function Image({ src, alt, className, height, width, id }) {
  return (
    <img
      src={src}
      alt={alt}
      className={className}
      height={height}
      width={width}
      id={id}
    />
  );
}

export default Image;
