import React from "react";
import $ from 'jquery';
import Image from "../components/common/Image";
import { Link } from "react-router-dom";
import whitelogo from "../assest/images/Logo-white-Yellow.png"
import blackwebsitelogo from "../assest/images/Logo-white-Yellow.png"
import bhara from "../assest/images/bhara.png"
import Loader from "../components/common/Loader";
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Constants } from '../Constants/Constants';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCart } from '../pages/CartContext';
import Swal from 'sweetalert';

function Header() {
  const { setCartTotalCount, cartTotalCount, cart, addToCart, removeFromCart } = useCart();
  const location = useLocation();
  const auth_token = localStorage.getItem('auth_token');
  const auth_name = localStorage.getItem('auth_name');
  const history = useNavigate();
  const [data,setData] = useState([]);
  const isInitialRender = useRef(true);
  const [showSearch, setShowsearch] = useState(false);
  const [logo, setlogo] = useState(null);

// Category Data ------
  useEffect(()=>{
    if (isInitialRender.current) {
        isInitialRender.current = false;
        return;
    }
    // Calling the API to get all the details of the order
    const fetchData = async () => {
        try {
          const response = await axios.get(`${Constants.REACT_APP_API_URL}api/get_all_cat`);
        //   console.log(response.data)
          if (response.data.status === true) {
              console.log("response_header",response.data.setting_data.company_logo);
              setlogo(response.data.setting_data.company_logo);
              setData(response.data.data)
            } else {
                console.log('NO data')
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    fetchData();
},[])

  /**
   * Search Products 
   */

  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);


  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = async () => {
    if (searchQuery !== '' && searchQuery.length >= 3) {
      try {
        const response = await axios.post(`${Constants.REACT_APP_API_URL}api/search_product`, {
          data: searchQuery
        }, {
          headers: {
            'Content-Type': 'application/json',
            // Additional headers if needed
          }
        });
        // Handle the success response
        if (response.data.status) {
          // console.log("products",response.data.products);
          setSearchResults(response.data.products);
        }
        else {
          console.log("something went wrong");
          setSearchResults('');
        }


      } catch (error) {
        // Handle errors
        console.error('Error during post:', error);
      }
    }
    else {
      console.log("something went wrong");
      setSearchResults('');
    }

  };

  useEffect(() => {
    handleSearch();
  }, [searchQuery]);


  /**
   * End Search Products
   */

  const handclick = () => {
    $('.search-input').toggleClass('d-none d-block');
    $('#productSearch').focus();
    $('#productSearch').val('');
    setSearchResults('');
    setSearchQuery('');
    $('.home-jon-profile').addClass('d-none').removeClass('d-block');
  
  }

  const handleLoginLogout = () => {
    $('.home-jon-profile').toggleClass('d-none d-block');
    $('.search-input').addClass('d-none').removeClass('d-block');
  }


  // const handleLogout = async () => {
  //   // Display a confirmation dialog
  //   const confirmLogout = window.confirm('Are you sure you want to logout?');

  //   // If the user confirms, proceed with the logout
  //   if (confirmLogout) {
  //     const header = {
  //       'Authorization': `Bearer ${auth_token}`, // Your token
  //       'Accept': 'application/json'
  //     };

  //     try {

  //       const response = await axios.get(`${Constants.REACT_APP_API_URL}api/logout`, {
  //         headers: header // Pass the headers within the options object
  //       });

  //       if (response.data.status == true) {
  //         localStorage.setItem('auth_token', '');
  //         localStorage.setItem('auth_name', '');
  //         localStorage.setItem('auth_id', '');
  //         setCartTotalCount(0);
  //         localStorage.setItem('cartcount', 0);
  //         history('/');
  //         toast.success("Logout successfully", {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }


  //       // Additional logic after successful logout, such as redirecting to the login page
  //     } catch (error) {
  //       console.error('Logout failed:', error);
  //     }

  //   }
  // }



  const handleLogout = async () => {
    Swal({
      title: 'Are you sure ?',
      icon: 'info',
      buttons: {
        yes: {
          text: 'Yes',
          value: true,
        },
        no: {
          text: 'No',
          value: false,
        },
      },
    }).then(async (value) => {
      if (value) {
        // If the user confirms, proceed with the logout
        const header = {
          'Authorization': `Bearer ${auth_token}`, // Your token
          'Accept': 'application/json'
        };


        const response = await axios.get(`${Constants.REACT_APP_API_URL}api/logout`, {
          headers: header
        });

        if (response.data.status === true) {

          localStorage.setItem('auth_token', '');
          localStorage.setItem('auth_name', '');
          localStorage.setItem('auth_id', '');
          setCartTotalCount(0);
          localStorage.setItem('cartcount', 0);
          // Assuming history is a React Router history object
          history('/');
          toast.success("Logout successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        else {
          toast.error("Logout failed. Please try again.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      } else {
        // User clicked "No", handle accordingly
        console.log('User clicked "No"');
      }
    });
  };



  return (
    <>

      <header className="sticky w-100">
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg  align-items-center justify-content-between d-flex">
            <Link className="navbar-brand" to="/">
              <Image src={whitelogo} alt="" className="logo-black-yellow" />
              <Image src={blackwebsitelogo} alt="" className="logo-change d-none" />
            </Link>

            <div className="collapse navbar-collapse justify-content-center" id="navbarNav">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link className="nav-link active text-white " aria-current="page" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white " to="/about-us">About Us</Link>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle text-white " to="#" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Categories
                  </Link>
                  <ul className="dropdown-menu">
                  {data.map((item) => (
                    <li><Link className="dropdown-item text-white " to={`/category/${item.slug}`}>{item.name}</Link></li>
                    ))}
                    
                    <div className="arrow-up"></div>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle text-white " to="#" role="button"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    Associate with Us
                  </Link>
                  <ul className="dropdown-menu">
                    <li><Link className="dropdown-item text-white " to="/become-channel-partner">Become a Channel partner</Link></li>
                    <li><Link className="dropdown-item text-white " to="/become-vendor">Become a Vendor</Link></li>
                    {/* <li><Link className="dropdown-item text-white " to="/project-customer">Project Customer</Link></li> */}
                    <div className="arrow-up"></div>
                  </ul>
                </li>
                <li className="nav-item">
                  <Link className="nav-link text-white " to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>

            <div className="d-flex align-items-center">

              <div className="user-profile ms-4 d-flex align-items-center">
                <div className="position-relative" >
                  {/*<i className="bi bi-search text-white  "></i>  */}
                  <svg onClick={handclick} width="24" height="24" viewBox="0 0 24 24" fill="none" className="me-4" id="bi-search" style={{ cursor: "pointer" }}>
                    <path d="M21 21L16.7501 16.7425M19.1053 11.0526C19.1053 13.1883 18.2569 15.2365 16.7467 16.7467C15.2365 18.2569 13.1883 19.1053 11.0526 19.1053C8.91694 19.1053 6.86872 18.2569 5.35856 16.7467C3.8484 15.2365 3 13.1883 3 11.0526C3 8.91694 3.8484 6.86872 5.35856 5.35856C6.86872 3.8484 8.91694 3 11.0526 3C13.1883 3 15.2365 3.8484 16.7467 5.35856C18.2569 6.86872 19.1053 8.91694 19.1053 11.0526Z" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                  </svg>
                  <div className="search-input position-absolute py-2 d-none ">
                    <div className="se-ip-box position-relative d-flex align-items-center ">
                      {/* <p className="mb-0"></p>  */}
                      <input
                        type="text"
                        className="border-0"
                        id="productSearch"
                        placeholder="Search by product name, sku"
                        value={searchQuery}
                        onInput={handleInputChange}
                      />
                      <Link to="" className=" for-bi-dearch rounded-circle align-items-center ms-2 d-flex justify-content-center cursor-pointer" id="product_searchbtn" onClick={handleSearch} >
                        <svg width="11" height="10" viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.5 9L7.61116 7.10779M8.65789 4.57895C8.65789 5.52814 8.28083 6.43846 7.60965 7.10965C6.93846 7.78083 6.02814 8.15789 5.07895 8.15789C4.12975 8.15789 3.21943 7.78083 2.54825 7.10965C1.87707 6.43846 1.5 5.52814 1.5 4.57895C1.5 3.62975 1.87707 2.71943 2.54825 2.04825C3.21943 1.37707 4.12975 1 5.07895 1C6.02814 1 6.93846 1.37707 7.60965 2.04825C8.28083 2.71943 8.65789 3.62975 8.65789 4.57895V4.57895Z" stroke="black" strokeWidth="1.5" strokeLinecap="round"></path>
                        </svg>
                      </Link>
                    </div>
                    <div className={`search-product ${searchResults.length > 0 ? "bt-top-padding" :'p-0'}`} id="searchResults">
                      {searchResults.length > 0 ? (
                        searchResults.map((result, index) => (

                          <Link to={`/product/${result.slug}`} onClick={handclick} className="text-decoration-none">
                            <div className="search-product-list d-flex align-items-center">
                              <div class="p-img">
                                <Image src={result.product_image} alt="" className="img" />
                              </div>
                              <span>{result.name}</span>
                            </div>
                          </Link>
                        ))
                      ) : (
                        searchQuery.length >= 3 ? <p className={`mb-0 ${searchResults.length == 0 ? "bt-top-padding" :''}`}><span style={{marginLeft: 13 + 'px'}}>No results found.</span></p> : ''
                      )}

                    </div>
                  </div>
                </div>

                {auth_token ? (

                  <div className="home-right-header d-flex align-items-center position-relative">

                    <div onClick={handleLoginLogout} className="hlu d-flex position-relative me-4 align-items-center">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" className="log-change">
                        <g clipPath="url(#clip0_210_1797)">
                          <path d="M12 22.5969C17.8524 22.5969 22.5968 17.8525 22.5968 12C22.5968 6.14746 17.8524 1.40319 12 1.40319C6.14738 1.40319 1.40312 6.14745 1.40312 12C1.40312 17.8525 6.14738 22.5969 12 22.5969Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M12.0002 14.3125C14.1093 14.3125 15.819 12.6028 15.819 10.4938C15.819 8.3848 14.1093 6.67509 12.0002 6.67509C9.89103 6.67509 8.18145 8.3848 8.18145 10.4938C8.18145 12.6028 9.89103 14.3125 12.0002 14.3125Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                          <mask id="path-3-inside-1_210_1797" fill="white">
                            <path d="M5.25995 21.0668C5.62135 17.6636 8.50097 15.0125 11.9999 15.0125C15.499 15.0125 18.3786 17.6638 18.7398 21.0669"></path>
                          </mask>
                          <path d="M6.65212 21.2146C6.57047 21.9835 5.88098 22.5406 5.11211 22.459C4.34323 22.3773 3.78613 21.6878 3.86778 20.919L6.65212 21.2146ZM20.132 20.9191C20.2136 21.688 19.6565 22.3775 18.8876 22.4591C18.1188 22.5407 17.4293 21.9836 17.3477 21.2147L20.132 20.9191ZM3.86778 20.919C4.30398 16.8114 7.77736 13.6125 11.9999 13.6125V16.4125C9.22458 16.4125 6.93873 18.5158 6.65212 21.2146L3.86778 20.919ZM11.9999 13.6125C16.2226 13.6125 19.696 16.8116 20.132 20.9191L17.3477 21.2147C17.0612 18.5159 14.7754 16.4125 11.9999 16.4125V13.6125Z" mask="url(#path-3-inside-1_210_1797)"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_210_1797">
                            <rect width="24" height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                      <h6 className="home-login-user mb-0 ms-2">
                        {auth_name}
                      </h6>
                    </div>

                    <div className="home-jon-profile position-absolute d-none">
                      <h4 className="fw-semibold mb-0"> {auth_name} </h4>
                      <Link to="/profile_update" className="update-profile">Update Profile</Link>
                      <hr className="border-0 opacity-100 " />
                      <h6 className="fw-medium mb-4">Manage</h6>
                      <div className="list">
                        <Link to="/orders" className="text-decoration-none fw-semibold d-flex align-items-center login-order"><Image src="https://infra-admin.orbitnapp.com/frontend/images/order.png" alt="" className="" />Orders</Link>
                        <Link to="/wishlist" className="text-decoration-none fw-semibold d-flex align-items-center login-wishlist"><Image src="https://infra-admin.orbitnapp.com/frontend/images/heart.png" alt="" />Wishlist</Link>
                        <Link to="/address" className="text-decoration-none fw-semibold d-flex align-items-center login-address"><Image src="https://infra-admin.orbitnapp.com/frontend/images/location.png" alt="" className="" />Address</Link>
                        <hr className="border-0 opacity-100 " />
                        <Link onClick={handleLogout} className="logout text-decoration-none fw-semibold d-flex align-items-center log-out cursor-pointer"><Image src="https://infra-admin.orbitnapp.com/frontend/images/logout.png" alt="" className="" />Log Out</Link>
                      </div>

                    </div>
                  </div>

                ) :

                  <div>
                    <Link to="/login" className="user-img">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_210_1797)">
                          <path d="M12 22.5969C17.8524 22.5969 22.5968 17.8525 22.5968 12C22.5968 6.14746 17.8524 1.40319 12 1.40319C6.14738 1.40319 1.40312 6.14745 1.40312 12C1.40312 17.8525 6.14738 22.5969 12 22.5969Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                          <path d="M12.0002 14.3125C14.1093 14.3125 15.819 12.6028 15.819 10.4938C15.819 8.3848 14.1093 6.67509 12.0002 6.67509C9.89103 6.67509 8.18145 8.3848 8.18145 10.4938C8.18145 12.6028 9.89103 14.3125 12.0002 14.3125Z" stroke="white" strokeWidth="1.4" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"></path>
                          <mask id="path-3-inside-1_210_1797" fill="white">
                            <path d="M5.25995 21.0668C5.62135 17.6636 8.50097 15.0125 11.9999 15.0125C15.499 15.0125 18.3786 17.6638 18.7398 21.0669"></path>
                          </mask>
                          <path d="M6.65212 21.2146C6.57047 21.9835 5.88098 22.5406 5.11211 22.459C4.34323 22.3773 3.78613 21.6878 3.86778 20.919L6.65212 21.2146ZM20.132 20.9191C20.2136 21.688 19.6565 22.3775 18.8876 22.4591C18.1188 22.5407 17.4293 21.9836 17.3477 21.2147L20.132 20.9191ZM3.86778 20.919C4.30398 16.8114 7.77736 13.6125 11.9999 13.6125V16.4125C9.22458 16.4125 6.93873 18.5158 6.65212 21.2146L3.86778 20.919ZM11.9999 13.6125C16.2226 13.6125 19.696 16.8116 20.132 20.9191L17.3477 21.2147C17.0612 18.5159 14.7754 16.4125 11.9999 16.4125V13.6125Z" mask="url(#path-3-inside-1_210_1797)"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_210_1797">
                            <rect width="24" height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>

                  </div>

                }



                <Link to="/cart" className="position-relative cart-btn ms-4">
                  <svg width="24" height="24" viewBox="0 0 24 24" className="" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.25 20.25C8.66421 20.25 9 19.9142 9 19.5C9 19.0858 8.66421 18.75 8.25 18.75C7.83579 18.75 7.5 19.0858 7.5 19.5C7.5 19.9142 7.83579 20.25 8.25 20.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M18.75 20.25C19.1642 20.25 19.5 19.9142 19.5 19.5C19.5 19.0858 19.1642 18.75 18.75 18.75C18.3358 18.75 18 19.0858 18 19.5C18 19.9142 18.3358 20.25 18.75 20.25Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M2.25 3.75H5.25L7.5 16.5H19.5" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M7.5 12.5H19.1925C19.2792 12.5001 19.3633 12.4701 19.4304 12.4151C19.4975 12.3601 19.5434 12.2836 19.5605 12.1986L20.9105 5.44859C20.9214 5.39417 20.92 5.338 20.9066 5.28414C20.8931 5.23029 20.8679 5.18009 20.8327 5.13717C20.7975 5.09426 20.7532 5.05969 20.703 5.03597C20.6528 5.01225 20.598 4.99996 20.5425 5H6" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path>
                  </svg>

                  {cartTotalCount != 0 ?
                    <span className="position-absolute rounded-circle" id="">
                      <p className="mb-0 d-flex align-items-center justify-content-center fw-semibold">{cartTotalCount}</p>
                    </span> : ''
                  }


                </Link>

              </div>


              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              <ToastContainer />
            </div>

          </nav>
        </div >
      </header >
    </>
  );
}
export default Header;
