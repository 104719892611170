import React, { useEffect, useState } from "react";

function Loader() {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    // Fakes the loading setting a timeout
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []); // Empty dependency array ensures this effect runs once when the component mounts

  // Function to show the div after a 2-second delay
  const showDivWithDelay = () => {
    const div = document.querySelector('.preloader-tube-tunnel');
    if (div) {
      setTimeout(() => {
        div.style.display = 'block';
      }, 1500); // 1500 milliseconds (1.5 seconds)
    }
  };

  // Use an effect to run the showDivWithDelay function after the component has mounted
  useEffect(() => {
    showDivWithDelay();
  }, []);

  // Use an effect to remove the loaderimg element after a 1.5-second delay
  useEffect(() => {
    const loaderimg = document.getElementById('loaderimg');
    if (loaderimg) {
      const removeElement = () => {
        loaderimg.remove();
      };

      setTimeout(removeElement, 1500);
    }
  }, []);

  // loader js End  




  return (
    <div id="loader-wrapper">
      <div id="loader">
        <div id="loaderimg"></div>
        <div className="preloader-tube-tunnel"></div>
      </div>
      <div className="loader-section section-left"></div>
      <div className="loader-section section-right"></div>
    </div>
  );
}

export default Loader;
