import React, { createContext, useState, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Constants } from '../Constants/Constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert';

const CartContext = createContext();
export const CartProvider = ({ children }) => {
  // Get or set the session ID from a cookie
 

const sessionId1 = Cookies.get('sessionId');
   
   if(sessionId1){
   }
   else{
    localStorage.setItem('cartcount', 0);
   }


   const auth_token = localStorage.getItem('auth_token');
   const header = {
           'Authorization': `Bearer ${auth_token}`, // Your token
           'Accept': 'application/json'
       };


  // Load cart from server using the session ID
  const [isCheckout, setCheckoout] = useState(null);
  const [cart, setCart] = useState([]);
  const [cartTotalCount, setCartTotalCount] =  useState(() => {
                      const cartitem = localStorage.getItem('cartcount');
                      return cartitem ? cartitem: 0;
                    });
  const [wishlist, setWishlist] = useState([]);

  


  useEffect(() => {
    //const navigate = useNavigate();
   // console.log("count=>",wishlist);
  }, [wishlist]);
  // useEffect(() => {
  //   // Fetch cart from the server using the session ID
  //   fetchCartFromServer(sessionId);
  // }, [sessionId]);

  // const fetchCartFromServer = async (sessionId) => {
  //   try {
  //     // const response = await axios.get(`${Constants.REACT_APP_API_URL}api/getcart`, {
  //     //   params: {
  //     //     session_id: sessionId,
  //     //   },
  //     //});

  //     // Update the local state with the cart data from the server
  //     //setCart(response.data.cart);
  //   } catch (error) {
  //     console.error('Error fetching cart from the server:', error);
  //   }
  // };



  const addToWhislists = async(product) => {
     
    console.log("product",product);

    try {
      const sessionId = Cookies.get('sessionId');
      // Update the cart on the server using the session ID
      const response = await axios.post(`${Constants.REACT_APP_API_URL}api/add_towhislist`, {
      //session_id: sessionId,
      product_id:product.id,
    },{
      headers: header,
  });
  

    if(response.data.status==true){
      setWishlist(product.id);
      return [{ status: true }];
      //localStorage.setItem('cartcount', response.data.count);
      //toast.success(response.data.message, {
       // position: toast.POSITION.TOP_RIGHT,
      //});
    }
    else{
      //toast.error(response.data.message, {
       // position: toast.POSITION.TOP_RIGHT,
      //});
      return false;

    }

    
  } catch (error) {
    console.error('Error updating Wislists on the server:', error);
    return false;
  }

  
   };

   

  const addToCart = (product,quantity) => {
   console.log("product", product);
  //  setCart((prevCart) => {
    //  const updatedCart = [...prevCart, product];
       updateCartOnServer(product,quantity);
    //  return updatedCart; 
  //  });
  };

  

  const removeFromCart = (productId) => {
    // Remove the product from the server-side cart
    updateCartOnServer(cart.filter(item => item.id !== productId));
  };

  const updateCartOnServer = async (product,quantity) => {
    try {
        const sessionId = Cookies.get('sessionId');
      // Update the cart on the server using the session ID
        const response = await axios.post(`${Constants.REACT_APP_API_URL}api/addcart`, {
        session_id: sessionId,
        product_id: product.id,
        qty: quantity,
        price: product.price
      },{
        headers: header,
    });

      if(response.data.status==true){
        setCartTotalCount(response.data.count)
        localStorage.setItem('cartcount', response.data.count);
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      else{
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });

      }
     // console.log("count=>",response);
      setTimeout(() => {
      }, 1000);

      // Update the local state with the new cart data
      setCart(product);
    } catch (error) {
      console.error('Error updating cart on the server:', error);
    }
  };

  // Log the cart items to the console whenever cart changes
  useEffect(() => {
   // console.log('Cart Items:', cart);
  }, [cart]);

  // Set the session ID cookie
  // useEffect(() => {
  //   Cookies.set('sessionId', sessionId);
  // }, [sessionId]);

  return (
    <CartContext.Provider value={{ setCheckoout,isCheckout,addToWhislists,cart, addToCart, removeFromCart,cartTotalCount,setCartTotalCount}}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

// Helper function to generate a unique session ID
const generateSessionId = () => {
  const timestamp = Date.now().toString(36); // Convert current timestamp to base36
  const randomPart = Math.random().toString(36).substring(2, 10); // Random portion
  const sessionId = timestamp + randomPart;
  return sessionId.padEnd(40, '0'); // Ensure the length is 40 characters
};
