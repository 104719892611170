import React from "react";
import Layout from '../layout/Layout'
import Image from '../components/common/Image'
import notfound from "../assest/images/page_not_found.jpg"


function NoPage() {
  return (
    <div className='dark-header'>
      <Layout>
        <section className="no-page-banner">
          <div className="not-found d-flex justify-content-center w-100">
            <Image src={notfound} alt="page Not Found" />
          </div>
        </section>
      </Layout>
    </div>
  );
}

export default NoPage;
