// reducers/index.js
import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "./slice/counterSlice"; // Import your reducer files here

const rootReducer = combineReducers({
  counter: counterReducer,
  // Add more reducers here
});

export default rootReducer;
