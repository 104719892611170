import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function BannerSkeleton() {
  return (
    <section className="home-slider">
      <Skeleton count={50} duration={2} />
      <SkeletonTheme color="#5e6c77" highlightColor="#a9b7c1">
        <p>
          <Skeleton count={3} duration={2} />
        </p>
      </SkeletonTheme>
    </section>
  );
}

export default BannerSkeleton;
