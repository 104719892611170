import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Image from "../components/common/Image";
import paypal from "../assest/images/paypal.png";
import whatsapp from "../assest/images/whatsapp.png";
import paypal1 from "../assest/images/paypal.png";
import axios from "axios";
import { Constants } from '../Constants/Constants';
import { useCart } from "../pages/CartContext";

function Footer() {
  const auth_token = localStorage.getItem("auth_token");
  const { addToCart, addToWhislists,setCartTotalCount, cartItemCount, cartTotalCount } = useCart();
  const fetchData = async () => {
    try {
        const header = {
            Authorization: `Bearer ${auth_token}`, // Your token
            Accept: "application/json",
          };
      const response = await axios.get(
        `${Constants.REACT_APP_API_URL}api/check_auth`,
        {
          headers: header,
        }
      );
      if (response.data.active_user.status=='2') {
        await localStorage.setItem('auth_token','')
        await setCartTotalCount(0);
        await localStorage.setItem("cartcount", 0);
        window.location.reload();
       } 
       else if (response.data.active_user.deleted_at !== null){
        await localStorage.setItem('auth_token','')
        await setCartTotalCount(0);
        await localStorage.setItem("cartcount", 0);
        window.location.reload();
       }
    } catch (error) {
      console.error("Error fetching data:", error);
    } 
  };

  useEffect(() => {
   
    fetchData();
  }, []);

  const [data,setData] = useState('')
  const isInitialRender = useRef(true);

  useEffect(()=>{
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
  }
    // Calling the API to get all the details of the customer
    const fetchData = async () => {
        try {
          const response = await axios.get(`${Constants.REACT_APP_API_URL}api/setting_data`)
          console.log(response.data.status)
    
          if (response.data.status == "true") {
          
            setData(response.data.setting_data[0])
            
          } else {
            console.log('NO data found')
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
    
      fetchData();
},[])

const [cat,setCat] = useState([]);
  const InitialRender = useRef(true);

// Category Data ------
  useEffect(()=>{
    if (InitialRender.current) {
        InitialRender.current = false;
        return;
    }
    // Calling the API to get all the details of the order
    const fetchData = async () => {
        try {
          const response = await axios.get(`${Constants.REACT_APP_API_URL}api/get_all_cat`,{
            params: {
              limit: 1,
          },
          });
        //   console.log(response.data)
          if (response.data.status === true) {
            setCat(response.data.data)
            } else {
                console.log('NO data')
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    fetchData();
},[])

// useEffect(()=>{
// console.log("kalak",data)
// },[data])
  return (
    <React.Fragment>

      <footer className=" footer pt-5 pb-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="2s" >
              <div className="infracorner">
                <h5 className=""> About Construction King </h5>
                <p> {data.description}
                </p>
                <div className="social-link d-flex align-items-center mt-4">
                  <Link to={`${data.facebook}`} target="_blank" className="text-white">
                    <i className="bi bi-facebook"></i>
                  </Link>
                  <Link to={`${data.twitter}`} target="_blank" className="text-white">
                    <i className="bi bi-twitter"></i>
                  </Link>
                  <Link to={`${data.instagram}`}  target="_blank" className="text-white">
                    <i className="bi bi-instagram"></i>
                  </Link>
                  <Link to={`${data.linkedin}`}  target="_blank" className="text-white">
                    <i className="bi bi-linkedin"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12 wow fadeInUp" data-wow-delay="0.4s" data-wow-duration="2s" >
              <div className="support">
                <h5> Reach Us </h5>
                <div className="mt-3 ">
                  <p className="mb-0 d-flex">
                    <i className="bi bi-geo-alt me-3"></i>
                    {data.address}
                  </p>
                  <ul className="ps-0 mb-0">
                    <li>
                      <Link to={`mailto:${data.email}`} className="text-decoration-none d-flex align-items-center">
                        <i className="bi bi-envelope me-3"></i>
                        {data.email}
                      </Link>
                    </li>
                    <li>
                      <Link to={`tel:${data.phone}`} className="text-decoration-none d-flex align-items-center">
                        <i className="bi bi-telephone me-3"></i>
                        {data.phone}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.6s" data-wow-duration="2s" >
              <div className="account">
                <h5> Account </h5>
                <ul className="ps-0 mb-0">
                  <li className="">
                    <Link to="/about-us" className="text-decoration-none">About Us</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions" className="text-decoration-none">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/faqs" className="text-decoration-none">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/contact-us" className="text-decoration-none">Contact/Support</Link>
                  </li>
                  <li>
                    <Link to="/refundPolicy" className="text-decoration-none">Refund Policy</Link>
                  </li>
                  <li>
                    <Link to="/shippingPolicy" className="text-decoration-none">Shipping Policy</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy" className="text-decoration-none">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 col-sm-6 col-6 wow fadeInUp" data-wow-delay="0.8s" data-wow-duration="2s" >
              <div className="account">
                <h5> Our Products </h5>
                <ul className="ps-0 mb-0">
                  {cat && cat.length > 0 ? cat.map((item) => (
                    <li><Link className="dropdown-item" to={`/category/${item.slug}`}>{item.name}</Link></li>
                    )) :''}
                  {/* <li><Link className="text-decoration-none" to="#">Bricks and Blocks</Link></li>

                  <li><Link className="text-decoration-none" to="#">Cement</Link></li>

                  <li><Link className="text-decoration-none" to="#">TMT Bar</Link></li> */}


                </ul>
              </div>
            </div>

          </div>
        </div>

        <hr className="border-0 w-100" />

        <div className="container">
          <div className="copyright d-flex justify-content-between align-items-center">
            <p className="mb-0">© Copyright 2024. All right reserved (Handled by Jai Bajrang Steels)</p>
            <div className=" d-flex justify-content-end">
              <Image src={paypal1} alt="" />
            </div>
          </div>
        </div>
        <div className="whatsapp-text">
          <Link aria-label="whatsapp" to="https://api.whatsapp.com/send?phone=918048211856&amp;text=Hello" className="whatsapp" target="_blank" rel="noopener">
            <Image src={whatsapp} alt="" />

          </Link>
        </div>





      </footer>

    </React.Fragment>
  );
}

export default Footer;
